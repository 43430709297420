@import "../../styles/global.scss";
.home {
  background-color: white;
  width: 100%;
  height: 100%;
}

.choose-elements {
  padding-top: 2%;
  padding-bottom: 2%;
  margin: auto;
  max-width: 90%;
  img {
    width: 80%;
    max-height: 10%;
    border-bottom: 1px solid black;
  }
}

.choose-categories {
  display: flex;
  flex-direction: row; 
  justify-content: space-around;
  padding: 20px 10%;
}

.list-container {
  display: flex;
  flex-direction: column; /* Asegura que los elementos estén uno debajo del otro */
  gap: 10px;
  width: 50%;
  text-align: left;
}

.list-item {
  position: relative;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  border-bottom: 2px solid $color-black;
  transition: border-color 0.3s ease;
  font-size: 60px;
  font-family: $font-family-description;
  font-weight: 400;
}

.arrow {
  position: absolute;
  margin-right: 20px;
  margin-top: 30px;
  font-size: 20px;
  transition: transform 0.3s ease;
  right: 0;
}

.arrow.down {
  transform: rotate(90deg); /* Rotación de la flecha cuando está activada */
}

.themes-container {
  margin-top: 20px; 
  max-width: 40%;
}

.themes-label {
  text-align: left;
  font-size: 80px;
  margin-bottom: 10px;
  font-family: $font-family-description;
  font-weight: 400;
}

.theme-circles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.theme-circle {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: $color-white;
  border: 2px solid $color-black;
  cursor: pointer;
}

.theme-circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 45%;
}

.theme-circle:hover {
  background-color: $color-gray-light;
}





@import "../../styles/global.scss";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Georgia', serif;
    background-color: #f7f3e9;
}

.calendar-notes-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    width: 99%;
    margin: 0 auto;
    gap: 20px;
    overflow-x: auto;
}

.notes-section, 
.other-pages-section {
    width: 15%; // Reduce el ancho para evitar que causen saltos de línea
    min-width: 150px; // Asegura que no colapsen en pantallas más pequeñas
    margin: 0 10px; // Añade un pequeño margen lateral
}


.calendar-container {
    flex: 1; 
    max-width: 60%;
    min-width: 800px; 
    aspect-ratio: 1/1;
    padding-top: 30px;
    position: relative;
    display: flex; 
    justify-content: space-between;
}

.calendar {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.calendar-overlay {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 900px;
}

.navigation {
    position: absolute;
    width: 15%;
    height: 15%;
    cursor: pointer;
    background: transparent;
    z-index: 2;
}

.navigation.previous-top {
    top: 6%;
    left: 2%; 
    border-bottom-right-radius: 60%;
}

.navigation.next-top {
    top: 4%;
    right: 3%; 
    border-bottom-left-radius: 60%;
}

.navigation.previous-bottom {
    top: 55%;
    left: 1%; 
    border-top-right-radius: 60%;
}

.navigation.next-bottom {
    top: 55%;
    right: 1%; 
    border-top-left-radius: 60%;
}

.navigation:hover {
    transform: scale(1.1);
}

.month-image-container {
    position: absolute;
    width: 50%;
    margin-bottom: 10px;
    z-index: 3;
    left: 0;
    margin-top: 45px;
    pointer-events: none;
}

.month-image {
    width: 80%; 
    height: auto;
    object-fit: contain;
    max-height: 150px;
}

.frame {
    width: 102%;
    height: auto; 
    position: absolute;
    top: 12px;
    left: -26px;
    z-index: 1; 
    transform: rotate(-2.5deg); 
}

.mushroom-layer1{
    position: absolute;
    top: 250px;
    left: -20px;
    z-index: 0; 
    width: 200px;
    height: auto;
    z-index: 2;
}

.mushroom-layer2{
    position: absolute;
    top: 370px;
    left: 600px;
    z-index: 0; 
    width: 200px;
    height: auto;
    z-index: 2;
}

.mushroom-layer3{
    position: absolute;
    top: 190px;
    left: 310px;
    z-index: 0; 
    width: 140px;
    height: auto;
    z-index: 3;
    pointer-events: none;
}

.mushroom-layer8{
    position: absolute;
    top: 90px;
    left: -20px;
    z-index: 0; 
    width: 200px;
    height: auto;
    z-index: 2;
}

.calendar-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; 
    width: 100%;
    height: auto;
}

.days-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    max-width: 900px;
    margin-top: 70px; 
}

.days-part-left,
.days-part-right {
    display: grid;
    gap: 10px;
    justify-items: center;
}

.days-part-left {
    grid-template-columns: repeat(3, 1fr);  
    grid-template-rows: repeat(4, 1fr);     
    width: 50%;                             
    padding-bottom: 0%; 
    padding-left: 15%;
    padding-top: 15%;
    padding-right: 4%;
    
}

.days-part-right {
    grid-template-columns: repeat(4, 1fr);  
    grid-template-rows: repeat(5, 1fr);     
    width: 50%;   
    padding-right: 7%;
    padding-left: 2%;
    padding-top: 7%;
    padding-bottom: px;
    margin-bottom: 3%;
}

.day-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0;
    padding-bottom: 100%; 
    background-color: #f0f0f0; 
    border: 1px dashed #7a7a7a; 
    border-radius: 4px;
    font-family: $font-family-description; 
    font-size: 20px;
    color: #3d3d3d; 
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease;
    transform: rotate(calc(-2deg + 4deg * random())); 
}

.day-container:hover {
    transform: scale(1.05) rotate(calc(-3deg + 6deg * random())); /* Efecto de hover con ligera rotación */
    background-color: #eaeaea; /* Color más claro en hover */
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25); /* Sombra más intensa en hover */
}

.day-container span {
    color: #4f4f4f; /* Gris oscuro, simulando lápiz */
    font-weight: normal;
    font-size: 22px; /* Tamaño del número */
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5); /* Sombra muy leve para simular la suavidad del lápiz */
    margin-top: 20px;
}

/* Ajuste adicional para que cada día tenga una ligera variación */
.day-container:nth-child(even) {
    transform: rotate(-2deg) translate(1px, -2px); /* Rotación y desplazamiento leve */
}

.day-container:nth-child(odd) {
    transform: rotate(2deg) translate(-2px, 1px); /* Rotación y desplazamiento leve */
}

.notes-section, .other-pages-section {
    font-family: 'Shadows Into Light', cursive; 
    font-size: 24px;
    color: #3d3d3d;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    max-width: 20%;
    margin-top: 50px;
}

.notes-section {
    margin-left: 10px;
}

.other-pages-section {
    margin-right: 10px;
}

.notes-section p, .other-pages-section p {
    margin-bottom: 10px;
}

.revision-container {
    width: 100%;
    padding-top: 20px;
    font-family: 'Shadows Into Light', cursive;
    font-size: 20px;
    color: #3d3d3d;
    margin-top: 50px;
}

.revision-container textarea {
    width: 90%;
    height: 100px;
    margin-top: 20px;
    font-family: 'Shadows Into Light', cursive;
    font-size: 20px;
    padding: 10px;
    border: 2px dashed #7a7a7a;
    border-radius: 4px;
    resize: none;
    background-color: #f9f9f9;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
}

.other-pages-lines{
    width: 100%;
}

.notes-lines {
    width: 100%;
    position: relative;
    z-index: 0; 
}

.note-textarea {
    position: absolute; 
    top: -5px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2); 
    border: 1px solid #ccc;
    box-sizing: border-box;
    z-index: 20;
    font-size: x-large;
}

.day-note{
    display: inline-block;
}

.calendar2 {
    width: 75%; /* Set the width to 75% of the parent */
    height: auto; /* Maintain aspect ratio */
}

.calendar-container2 {
    width: 100%; /* Ensure the container takes full width */
    height: auto; /* Maintain aspect ratio */
}

.calendar-image2 {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
}


.calendar-container2 {
    position: relative; /* Set the parent to relative for absolute positioning */
}

.month-image-container {
    position: absolute; /* Position the month image absolutely */
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    z-index: 10; /* Ensure it appears above other elements */
}

.month-image {
    width: 225px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
}

.calendar3 {
    width: 75%; /* Set the width to 75% of the parent */
    height: auto; /* Maintain aspect ratio */
}

.calendar-container3 {
    width: 100%; /* Ensure the container takes full width */
    height: auto; /* Maintain aspect ratio */
}

.calendar-image3 {
    width: 100%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
}


.calendar-container3 {
    position: relative; /* Set the parent to relative for absolute positioning */
}


.calendar-container4 {
    width: 100%; /* Ensure the container takes full width */
    height: auto; /* Maintain aspect ratio */
}

.calendar-image4 {
    width: 200%; /* Make the image responsive */
    height: auto; /* Maintain aspect ratio */
}


.calendar-container4 {
    position: relative; /* Set the parent to relative for absolute positioning */
}




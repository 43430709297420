.crayon-lines {
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;
}

.crayon-line {
    height: 2px;
    width: 100%;
    background-color: black;
    margin-top: 15px;
    position: relative;
}

.crayon-line-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: black;
    font-family: "Courier New", Courier, monospace;
    font-size: 12px;
    cursor: text;
    padding: 0;
    text-align: left;
    white-space: nowrap;
    line-height: 2px;
}

.crayon-line-input::placeholder {
    color: transparent;
}

.crayon-line-input:focus {
    outline: none;
}
